
// ***************************************************
// Shopping Cart functions

var shoppingCart = (function () {
    // Private methods and properties
    var cart = [];

    function Item(name, id, count, price, color, img) {
        this.name = name;
		this.id = id;
		this.count = count * 1;
		this.price = price * 1;
        this.color = color;
        this.img = img;
    }

    function saveCart() {
        localStorage.setItem("shoppingCart", JSON.stringify(cart));
    }

    function loadCart() {
        cart = JSON.parse(localStorage.getItem("shoppingCart"));
        if (cart === null) {
            cart = []
        }
    }

    loadCart();



    // Public methods and properties
    var obj = {};

    obj.addItemToCart = function (name, id, count, price, color, img) {
        for(var i in cart) {
			if(cart[i].id === id){
                if(cart[i].color === color) {
                    if(cart[i].count <=3 ) {
                        cart[i].count = 3
                        saveCart();
                        return;
                    } else {
                        cart[i].count +=  parseInt(count);
                        saveCart();
                        return;
                    }
                }
			}
		}

        console.log("addItemToCart:", name, id, count, price, color, img);

        var item = new Item(name, id, count, price, color, img);
        cart.push(item);
        saveCart();
    };

    //  btnplus(id, color, count)
    obj.btnplus = function (id, color, count) {
		for(var i in cart) {
			if(cart[i].id === id){
                if(cart[i].color === color){
                    if (cart[i].count < 3){
                        cart[i].count += 1;
                        saveCart();
                        return;
                    }
                }
			}
		}
	}

    //  btnless(id, color, count)
    obj.btnless = function (id, color, count) {
		for(var i in cart) {
			if(cart[i].id === id){
                if(cart[i].color === color){
                    if (cart[i].count > 1){
                        cart[i].count -= 1;
                        saveCart();
                        return;
                    }
                }
			}
		}
	}

	obj.setCountForItem = function (id, count) {
        for (var i in cart) {
            if (cart[i].id === id) {
                cart[i].count = count;
                break;
            }
        }
        saveCart();
    };

    obj.removeItemFromCart = function (name) { // Removes one item
        for(var i in cart) {
			if(cart[i].name === name){		// "3" === 3 false
				cart[i].count --;
				if (cart[i].count === 0) {
					cart.splice(i, 1);
				}
				break;
			}
		}
		saveCart();
    };

    obj.removeItemFromCartAll = function (id, color) { // removes all item name
        for(var i in cart) {
			if(cart[i].id === id){		// "3" === 3 false
                if (cart[i].color === color) {
                    cart.splice(i, 1);
				    break;
                }
			}
		}
		saveCart();
    };

    obj.clearCart = function () {
        cart = [];
        saveCart();
    }

    obj.allCount = function (id){		// return total count
		var allcount = 0;
		for(var i in cart) {
			var item = cart[i];
			if ( id == null || item.id == id) {
	            allcount++;
	        }
		}
		return allcount;
	}

    obj.countCart = function () { // -> return total count
        var totalCount = 0;
		for(var i in cart) {
			totalCount += cart[i].count;
		}
		return totalCount;
    };

    obj.totalCart = function () { // -> return total cost
        var totalCost = 0;
		for(var i in cart) {
			totalCost += cart[i].price * cart[i].count;
		}
		return totalCost;
    };

    obj.listCart = function () { // -> array of Items
        var cartCopy = [];
		for (var i in cart) {
			var item = cart[i];
			var itemCopy = {};
			for (var p in item) {
				itemCopy[p] = item[p];
			}
			cartCopy.push(itemCopy);
		}
		return cartCopy;
    };

    // ----------------------------
    return obj;
})();




